import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const style = css(
  mq({
    marginTop: [49.5, 50, 47, 47],
    marginBottom: [50]
  })
)

export const ContentBps = css(
  mq({
    width: [328, '100%', '100%', '100%'],
    marginLeft: [0, 0, 200, 233],
    marginRight: [0, 0, 200, 238],
    textAlign: ['left']
  })
)

export const QuotesBps = css(
  mq({
    width: [328, '100%']
  })
)

export const borderLine = css`
  ::after {
    content: "";
    display: block;
    width: 50px; 
    margin-top: 16px;
    border-bottom: 4px solid #9013fe; 
    border-radius: 6px;
  }
  ::before {
    content: "";
    display: block;
    width: 50px; 
    margin-bottom: 16px;
    border-bottom: 4px solid #9013fe; 
    border-radius: 6px;
  }
`

export const contentPost = css`
  h2 {
    font-size: 23.7px;
    font-weight: 500;
  }
  h2:not(:last-child) {
    margin-bottom: 16px;
  }
  h4, h3, h2 {
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #44474e;
    strong {
      font-size: 24px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #44474e;
    }
  }
  h4, h3:not(:last-child) {
    margin-bottom: 30px;
    strong {
      margin-bottom: 30px;
    }
  }
  p {
    font-family: 'Lora', serif;
    font-size: 18px;
    font-weight: 'normal';
    font-stretch: normal;
    font-style: normal;
    line-height: 1.83;
    letter-spacing: normal;
    color: #44474e;
    a {
      color: #9013fe;
    }
    a:hover {
      font-weight: bold;
    }
    strong {
      font-weight: 600;
    }
  }
  p:not(:last-child) {
    margin-bottom: 24px;
  }
  strong {
    font-weight: 600;
  }
  em {
    font-style: italic;
  }
  img {
    max-width: 100%;
    width: 100%;
    border-radius: 6px;
  }
  ul {
    font-family: 'Lora', serif;
    list-style-type: square;
    font-size: 18px;
    line-height: 1.83;
    letter-spacing: normal;
    color: #44474e;
    padding-left: 40px;
    margin-bottom: 24px;
    a {
      color: #9013fe;
    }
    li:not(:last-child) {
      margin-bottom: 12px;
    }
  }
  ol {
    font-family: 'Lora', serif;
    list-style-type: square;
    font-size: 18px;
    line-height: 1.83;
    letter-spacing: normal;
    color: #44474e;
    padding-left: 40px;
    margin-bottom: 24px;
    a {
      color: #9013fe;
    }
    li:not(:last-child) {
      margin-bottom: 12px;
    }
  }
  figure {
    margin-bottom: 24px;
    width: 100%;
    max-width: 100%;
    div {
      width: 100% !important;
    }
  }
  iframe {
    width: 100%;
  }
  figcaption {
    font-size: 14px;
    line-height: 1.71;
    font-weight: 500;
    font-family: 'Montserrat',sans-serif !important;
    color: #878694;
    text-align: center;
    em {
      font-style: normal
    }
  }
  blockquote {
    ${borderLine};
    ${QuotesBps};
    margin-top: 48px;
    margin-bottom: 48px;
    p, cite {
      font-family: 'Lora', serif;
      font-size: 24px;
      font-weight: 'normal';
      font-stretch: normal;
      line-height: 1.67;
      letter-spacing: normal;
      color: #212121 !important;
      em {
        font-style: normal !important;
      }
    }
  }
`

export const dots = css`
  width: 4px;
  height: 4px;
  background-color: #44474e;
  border-radius: 50%;
  :not(:last-child) {
    margin-right: 30px;
  }
`

export const relatedPost = css`
  .row:first-child(1) {
    margin-bottom: 40px;
  }
`

export const postSummary = css`
  font-family: 'Lora', serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: #44474e;
  margin-bottom: 16px;
`
export const readMoreButton = css`
  display: flex;
  width: 150px;
  height: 30px;
  border-radius: 15px;
  background-color: #fbf7ff;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9013fe;
  justify-content: center;
  align-items: center;
`